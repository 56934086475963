export const noOfmonths = {
  8: 12,
  17: 12,
  14: 6,
  7: 3,
  6: 3,
}

export const monthsName = {
  1: 'jan',
  2: 'feb',
  3: 'mar',
  4: 'apr',
  5: 'may',
  6: 'jun',
  7: 'jul',
  8: 'aug',
  9: 'sep',
  10: 'oct',
  11: 'nov',
  12: 'dec',
}

export const totalTargetType = {
  'H1 (APR-SEPT)': 'h1_total_target_value',
  'H2 (OCT-MAR)': 'h2_total_target_value',
  'Q1 (APR-JUN)': 'q1_total_target_value',
  'Q2 (JUL-SEPT)': 'q2_total_target_value',
  'Q3 (OCT-DEC)': 'q3_total_target_value',
  'Q4 (JAN-MAR)': 'q4_total_target_value',
  JANUARY: 'jan_total_target_value',
  FEBRUARY: 'feb_total_target_value',
  MARCH: 'mar_total_target_value',
  APRIL: 'apr_total_target_value',
  MAY: 'may_total_target_value',
  JUNE: 'jun_total_target_value',
  JULY: 'jul_total_target_value',
  AUGUST: 'aug_total_target_value',
  SEPTEMBER: 'sep_total_target_value',
  OCTOBER: 'oct_total_target_value',
  NOVEMBER: 'nov_total_target_value',
  DECEMBER: 'dec_total_target_value',
}

export const periodFlag = {
  'H1 (APR-SEPT)': 'flag_h1',
  'H2 (OCT-MAR)': 'flag_h2',
  'Q1 (APR-JUN)': 'flag_q1',
  'Q2 (JUL-SEPT)': 'flag_q2',
  'Q3 (OCT-DEC)': 'flag_q3',
  'Q4 (JAN-MAR)': 'flag_q4',
  JANUARY: 'flag_jan',
  FEBRUARY: 'flag_feb',
  MARCH: 'flag_mar',
  APRIL: 'flag_apr',
  MAY: 'flag_may',
  JUNE: 'flag_jun',
  JULY: 'flag_jul',
  AUGUST: 'flag_aug',
  SEPTEMBER: 'flag_sep',
  OCTOBER: 'flag_oct',
  NOVEMBER: 'flag_nov',
  DECEMBER: 'flag_dec',
}

export const instructionsData = [
  {
    columns: [
      { title: '' },
      {
        title: 'Instructions',
        width: { wpx: 450 },
        style: { font: { bold: true } },
      },
    ],
    data: [
      [{ value: '' }, { value: "Don't change the Headers" }],
      [
        { value: '' },
        { value: "Don't give spaces before and after any field value" },
      ],
      [{ value: '' }, { value: "Don't use the text :(blank) " }],
      [
        { value: '' },
        {
          value:
            'Use only the templates available in the system for target uploads',
        },
      ],
      [{ value: '' }, { value: "Don't highlight the fields which are blank" }],
      [{ value: '' }, { value: "Don't enter duplicate records" }],
      [{ value: '' }, { value: 'Use unique email id for each user' }],
      [
        { value: '' },
        {
          value:
            'To mark an employee as Outlier set the outlier field valus as 1',
        },
      ],
      [
        { value: '' },
        {
          value:
            'Please remove the instruction sheet before uploading the file',
          style: { font: { bold: true, color: { rgb: 'FFFF0000' } } },
        },
      ],
    ],
  },
]

export const allocatedInstructionsData = [
  {
    columns: [
      { title: '' },
      {
        title: 'Instructions',
        width: { wpx: 450 },
        style: { font: { bold: true } },
      },
    ],
    data: [
      [{ value: '' }, { value: "Don't change the Headers" }],
      [
        { value: '' },
        { value: "Don't give spaces before and after any field value" },
      ],
      [{ value: '' }, { value: "Don't use the text :(blank) " }],
      [
        { value: '' },
        {
          value:
            'Use only the templates available in the system for target uploads',
        },
      ],
      [{ value: '' }, { value: "Don't highlight the fields which are blank" }],
      [{ value: '' }, { value: "Don't enter duplicate records" }],
      [{ value: '' }, { value: 'Use unique email id for each user' }],
      [
        { value: '' },
        {
          value:
            'Please remove the instruction sheet before uploading the file',
          style: { font: { bold: true, color: { rgb: 'FFFF0000' } } },
        },
      ],
    ],
  },
]
